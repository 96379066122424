import React from 'react'
import styled from 'styled-components';
export default function SaveTemplateDoc() {
    return (
        <ModalAlert>
            <div className="box">
                img
                <p className="mb-0 title">
                    Your Template Has Been Saved
                </p>
                <button className="close-modal-btn">Close</button>
            </div>
        </ModalAlert>
    )
}


const ModalAlert = styled.div`
height:100vh;
width:100%;
background:#212E33;


.box{
    height:240px;
    width:555px;
    display:flex;
    align-items:center;
    justify-content:space-around;
    flex-direction:column;


    .title{
        font-family: Roboto;
        font-size: 24px;
        font-weight: 300;
        color:#212E33;
    }

    .close-modal-btn{
        height: 44px;
        width: 122px;
        border-radius: 3px;
        background-color: #F7941C;
        outline:none;
        border:none;
        text-transform:uppercase;
        font-family: Rajdhani;
        font-size: 16px;
        font-weight: 600;
    }
}


`