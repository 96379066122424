import React from 'react'
import SaveTemplateDoc from '../Components/SaveTemplateDoc'

export default function saveTemplateModal() {
    return (
        <div>
            <SaveTemplateDoc/>
        </div>
    )
}
